<template>
    <div>
        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title m-0">
                    <h3 class="card-label m-0">
                        {{ $t('cheque_book.cheque_book')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
            </div>
            <div class="card-body">

                    <div class="card-body p-0">
                        <div class="form-group row">
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('cheque_book.bank')}}<span class="text-danger">*</span></label>
                                <div class="input-group">
                                    <multiselect v-model="bank"
                                        :placeholder="$t('cheque_book.bank')"
                                        label="name"
                                        track-by="id"
                                        :options="banks"
                                        :multiple="false"
                                        :taggable="false"
                                        :show-labels="false"
                                        :show-no-options="false"
                                        :show-no-results="false">
                                    </multiselect>
                                    <div class="input-group-prepend">
                                        <a class="btn btn-primary" href="/finances/banks/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                                    </div>
                                </div>
                                <span v-if="validation && validation.bank_id" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.bank_id[0] }}
                                </span>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('cheque_book.bank_booking_no')}}<span class="text-danger">*</span></label>
                                <input type="number" v-model="data.bank_booking_no" class="form-control" :class="validation && validation.bank_booking_no ? 'is-invalid' : ''"/>
                                <span v-if="validation && validation.bank_booking_no" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.bank_booking_no[0] }}
                                </span>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('cheque_book.first_serial')}}<span class="text-danger">*</span></label>
                                <input type="number" v-model="data.first_serial" class="form-control" min="1" :class="validation && validation.first_serial ? 'is-invalid' : ''"/>
                                <span v-if="validation && validation.first_serial" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.first_serial[0] }}
                                </span>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('cheque_book.last_serial')}}<span class="text-danger">*</span></label>
                                <input type="number" v-model="data.last_serial" min="1" class="form-control" :class="{'is-invalid' : ((validation && validation.last_serial) || show_last_serial_message)}"/>
                                <span v-if="show_last_serial_message" class="fv-plugins-message-container invalid-feedback">
                                    {{ last_serial_message }}
                                </span>
                                <span v-else-if="validation && validation.last_serial" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.last_serial[0] }}
                                </span>

                            </div>

                            <div class="col-lg-6 mb-5">
                                <label>{{$t('cheque_book.currency')}}</label>
                                 <div class="input-group">
                                    <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                                        <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                                    </select>
                                    <div class="input-group-prepend">
                                        <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                                    </div>
                                </div>
                                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.currency_id[0] }}
                                </span>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('purchases_orders.branch')}}</label>
                                <div class="input-group">
                                    <multiselect v-model="branch"
                                        :placeholder="$t('cheque_book.branch')"
                                        label="name"
                                        track-by="id"
                                        :options="branches"
                                        :multiple="false"
                                        :taggable="false"
                                        :show-labels="false"
                                        :show-no-options="false"
                                        :show-no-results="false">
                                    </multiselect>
                                    <div class="input-group-prepend">
                                        <a class="btn btn-primary" href="/settings/branches/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                                    </div>
                                </div>
                                <span v-if="validation && validation.branch_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.branch_id[0] }}
                            </span>
                            </div>


                            <div class="col-lg-6 mb-5">
                                <label>{{$t('cheque_book.notes')}}</label>
                                <textarea name="" id="notes" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                                <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.notes[0] }}
                                </span>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <b-form-checkbox size="lg" v-model="data.status" name="check-button" switch>{{$t('status')}}</b-form-checkbox>
                            </div>

                        </div>
                    </div>
                    <div class="card-footer pl-0 pr-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
                                <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <!--end::customer-->
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import {mapGetters, mapState} from "vuex";

    export default {
        name: "form-cheques-cheque-book",
        data() {
            return {
                mainRoute: 'chequescycle/book-cheques',
                mainRouteDependency: 'base/dependency',

                data: {
                    bank_booking_no: null,
                    bank_id: null,
                    branch_id: null,
                    currency_id: null,
                    status: true,
                    first_serial: null,
                    last_serial: null,
                    notes: null,
                },
                idEditing: this.$route.params.id ? this.$route.params.id : null,
                isEditing: false,
                show_last_serial_message: false,

                currencies: [],
                branches: [],
                banks: [],
                branch: null,
                bank: null,

                validation: null,
                last_serial_message: this.$t('must_last_serial_be_grater_than_first_serial'),

            };
        },
        computed: {
            ...mapState({user_personal_info: state => state.profile.user_personal_info}),
            ...mapGetters(["currentUserPersonalInfo"]),

        },
        watch:{

            branch: function (val) {
                if (val) {
                    this.data.branch_id = val.id;
                } 
            },
            bank: function (val) {
                if (val) {
                    this.data.bank_id = val.id;
                } 
            },
            'data.first_serial': function (val) {
                setTimeout(()=>{
                    if (val && this.data.last_serial){
                        if (parseInt(val) > parseInt(this.data.last_serial)){
                            this.show_last_serial_message = true;
                        }else {
                            let _calc = parseInt(this.data.last_serial) - parseInt(val);
                            if (_calc == 150){
                                this.show_last_serial_message = false;
                            }else {
                                this.show_last_serial_message = true;
                            }
                        }
                    }
                }, 100);
            },
            'data.last_serial': function (val) {
                setTimeout(()=>{
                    if (val && this.data.first_serial){
                        if (parseInt(val) < parseInt(this.data.first_serial)){
                            this.show_last_serial_message = true;
                        }else {
                            let _calc = parseInt(val) - parseInt(this.data.first_serial);
                            if (_calc == 150){
                                this.show_last_serial_message = false;
                            }else {
                                this.show_last_serial_message = true;
                            }
                        }
                    }
                }, 100);

            },
        },
        methods: {
            save() {
                if (this.show_last_serial_message){
                    return false;
                }
                if (this.isEditing) {
                    this.update();
                } else {
                    this.create();
                }
            },

            create() {
                ApiService.post(`${this.mainRoute}`, {
                    ...this.data,
                })
                    .then((response) => {
                        this.validation = null;
                        this.$successAlert(response.data.message);
                        this.$router.push({name: 'cheque-book.index'});
                    })
                    .catch((error) => {
                        this.$errorAlert(error);
                        this.validation = error.response ? error.response.data.errors : null;
                    });
            },


            update() {
                ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
                    ...this.data,
                })
                    .then((response) => {
                        this.validation = null;
                        this.$router.push({name: 'cheque-book.index'});
                        this.$successAlert(response.data.message);
                    })
                    .catch((error) => {
                        this.$errorAlert(error);
                        this.validation = error.response ? error.response.data.errors : null;
                    });
            },

            async getData() {
                ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
                    this.isEditing = true;
                    this.data.bank_booking_no = response.data.data.bank_booking_no;
                    this.data.bank_id = response.data.data.bank_id;
                    this.data.branch_id = response.data.data.branch_id;
                    this.bank = response.data.data.bank;
                    this.branch = response.data.data.branch;

                    this.data.currency_id = response.data.data.currency_id;
                    this.data.status = response.data.data.status;
                    this.data.first_serial = response.data.data.first_serial;
                    this.data.last_serial = response.data.data.last_serial;
                    this.data.notes = response.data.data.notes;

                });
            },

            getCurrencies() {
                ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
                    this.currencies = response.data.data;
                });
            },


            getBranches() {
                ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
                    this.branches = response.data.data;
                });
            },

            getBanks() {
                ApiService.get(`${this.mainRouteDependency}/banks`).then((response) => {
                    this.banks = response.data.data;
                });
            },


            defaultDataForUser(){
                ApiService.get(this.mainRouteDependency +"/default_data_for_user").then((response) => {
                    this.data.currency_id = response.data.data.currency_id;
                    this.branch = response.data.data.branch;
                    this.data.branch_id = response.data.data.branch_id;
                });
            }

        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.cheque_book"),route:'/cheques-cycle/cheque-book'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

            this.getCurrencies();
            this.getBranches();
            this.getBanks();

            if (this.idEditing) {
                this.getData();
            }else {
              this.defaultDataForUser();

            }

        },
    };
</script>